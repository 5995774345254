//
// Theme style
//

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import './core/layout/base';
@import 'layout/layout';

.splash_content {
  background: black;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.height-400 {
  max-height: 800px;
  overflow: scroll;
}

.menu-item .svg-icon-2 > svg {
  fill: #808080;
}

.menu-item .active svg {
  fill: #ffffff;
}

.btn-xs {
  font-size: 12px !important;
  padding: 5px !important;
}

#custom-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#custom-table td,
#custom-table th {
}

#custom-table th {
  padding-top: 1px;
  padding-bottom: 1px;
  text-align: left;
}

#timesheet-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border-bottom: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  margin-top: 20px;
}

#timesheet-table thead tr {
  background: transparent !important;
  color: #000 !important;
  font-weight: 600;
}

#timesheet-table thead tr th {
  border-color: #eee;
  border-bottom: 2px solid #cccc;
  font-size: 16px;
  padding: 0;
  color: #444;
}

#timesheet-table tbody tr td {
  border-color: #ccc;
  font-size: 15px !important;
  color: #444;
}

#timesheet-table tbody tr td table#custom-table {
  border-color: #ccc !important;
}

#timesheet-table tbody tr td table#custom-table tr:nth-child(even) {
  background: transparent;
}

#timesheet-table tbody tr td table#custom-table tr td {
  font-weight: 600;
  font-size: 15px !important;
  border: 0;
}

#timesheet-table tbody tr.bg-primary {
  background: transparent !important;
  color: #000 !important;
}

#timesheet-table tbody tr td table#custom-table tr.bg-primary.text-white th {
  font-size: 15px !important;
  padding: 0;
  font-weight: 600;
  color: #ccc;
  border: 0;
}

#timesheet-table tbody tr.bg-primary {
  background: transparent !important;
  color: #000 !important;
}

#timesheet-table tbody tr.bg-primary td {
  border: 0 !important;
  font-weight: 600;
  color: #444;
}

#timesheet-table td,
#timesheet-table th {
  border: 1px solid black;
  padding: 5px;
}

#timesheet-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

#timesheet-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
}

#timesheet-table td {
  text-align: center;
}

#custom-table th {
  font-size: 10px !important;
}

#custom-table td {
  font-size: 10px !important;
}

.aside-dark .menu .menu-item.hover:not(.here) > .menu-link:not(.disabled):not(.active):not(.here),
.aside-dark .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) {
  transition: color 0.2s ease;
  background-color: transparent !important;
  color: grey !important;
}

table.time__sheet__table {
  margin: auto;
  width: 100%;
  border: 2px solid #000;
  border-top: 0;
  height: 1px;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}

.time__sheet__table tbody tr.inner-table:nth-child(2) {
  border: 0;
}

table.time__sheet__table table {
  height: 1px;
}

table.time__sheet__table td {
  border-right: 1px solid rgb(186, 176, 176);
  border-bottom: 1px solid rgb(186, 176, 176);
  padding: 0;
}

.time__sheet__table .inner-table {
  border-left: 0px solid;
  border-right: 0px solid;
}

.time__sheet__table .inner-table tr {
  text-align: center;
}

.time__sheet__table .inner-table td {
  border: 0;
  padding: 3px;
  margin: 0px;
  font-size: 12px;
}

/* .time__sheet__table .border-tb {
  border-top: 1px solid #000;
  border-bottom: 2px solid #000;
} */
.time__sheet__table .border-tb .head-row > th .full-height {
  border-bottom: 2px solid #000 !important;
  border-top: 2px solid #000 !important;
  left: 0;
  border-right: 0;
}

/* .time__sheet__table thead .border-tb .head-row>th:last-child .full-height {
  border-right: 2px solid #000 !important;
} */
.time__sheet__table thead.border-tb .head-row > th:first-child .full-height {
  // border-left: 0;
  border-right: 2px solid !important;
}

.time__sheet__table thead.border-tb .head-row > th:nth-child(2) .full-height {
  border-left: 0;
}

.time__sheet__table tbody .border-tb > th .full-height {
  // border-left: 0;
  left: 0;
  border-bottom: 1px solid rgb(186, 176, 176);
}

.time__sheet__table thead.border-tb .head-row > th:last-child .full-height {
  border-right: 2px solid #000 !important;
  right: 0;
  border-left: 2px solid !important;
}

// div#kt_content .datatable-cont .table-responsive {
//   border-bottom: 2px solid;
//   border-left: 2px solid;
// }
// table.time__sheet__table tbody tr.border-tb td.align-bottom.l-box table tbody.last-box td:last-child {
//   border-bottom: 1px solid rgb(186, 176, 176);
//   bottom: 0px;
//   position: relative;
// }

table.time__sheet__table tr {
  text-align: center;
}

.time__sheet__table .border-0 {
  height: 150px;
}

.time__sheet__table .border-0 thead {
  color: #d6d9d6;
}

.time__sheet__table .border-0 thead th {
  // padding-left: 20px;
}

.time__sheet__table .border-top {
  border-top: 1px solid rgb(186, 176, 176);
}

.time__sheet__table th {
  font-weight: 600;
}

.time__sheet__table .border-lr {
  border-left: 1px solid rgb(186, 176, 176);
  border-right: rgb(186, 176, 176);
  padding-left: 16px;
  padding-right: 20px;
  font-weight: 600;
}

.time__sheet__table .grey {
  color: #f9f9f8;
}

.time__sheet__table .fw-bold {
  font-weight: 600 !important;
}

tr.border-top-total {
  border-top: 1px solid rgb(186, 176, 176) !important;
}

.aside-dark .menu .menu-item.show > .menu-link {
  transition: color 0.2s ease;
  background-color: unset !important;
  color: var(--kt-primary-inverse);
}

.aside-dark .menu .menu-item.here > .menu-link {
  transition: color 0.2s ease;
  background-color: unset !important;
  color: var(--kt-primary-inverse);
}

.dropdown-toggle::after {
  display: none !important;
  background-color: white;
}

#dropdown-basic:hover {
  background-color: white !important;
}

#dropdown-basic:active {
  background-color: white !important;
}

#dropdown-basic {
  background-color: white !important;
}

#flagedit:hover {
  background-color: red;
  border: '1px solid black';
}

.text-color-blue {
  color: deepskyblue;
}

.customCard #customFiles-blue {
  background-color: #e1f1fa;
  border-style: dashed;
  border-color: deepskyblue;
  border-radius: 11px;
  border-width: 2px;
}

.card_custom_container {
  max-width: 1600px;
  margin: auto;
}

.parent-global-search .card-title input {
  border-radius: 50px;
  border: 1px solid lightgray;
}

.react-datepicker__day-names,
.react-datepicker__week {
  display: flex;
}

.gelpCx {
  overflow-y: visible !important;
  overflow-x: visible !important;
}

// for weeklytimecardmodal stamp custom-timestamp
.custom-timestamp .c-row-heads {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.custom-timestamp .c-clock-in {
  border-radius: 0;
  justify-content: center;
  background: none;
}

.custom-timestamp .c-clock-out {
  border-radius: 0;
  justify-content: center;
  background: none;
}

.custom-timestamp input {
  padding: 10px;
}

.custom-data-table > div {
  overflow: auto !important;
}

.custom-row h6 {
  font-size: 12px;
  color: #b6b7ba;
}

.custom-row span {
  font-size: 12px;
  font-weight: 600;
}

.time__sheet__table thead tr > th:first-child {
  background: #fff;
}

.time__sheet__table tbody tr tr th:first-child {
  position: static;
  background: none;
}

.time__sheet__table thead tr > th:first-child,
.time__sheet__table tbody tr > th:first-child {
  position: sticky;
  left: 0;
  height: 100%;
}

.time__sheet__table .border-tb .full-height {
  border-spacing: 0px;
  padding: 0px;
  margin: 0;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: -2px;
  border-right: 1px solid #ccc;
  padding-left: 16px;
  padding-right: 20px;
  font-weight: 600;
  border-left: 2px solid;
}

.time__sheet__table .border-tb .head-row {
  position: sticky;
  top: 0;
  z-index: 9;
}

.l-box {
  background: #fff;
  position: sticky;
  right: 0;
}

.w-time-border {
  border-bottom: 2px solid #000 !important;
  border-left: 1px solid #000 !important;
  border-top: 1px solid rgb(186, 176, 176);
  position: absolute;
  top: 0;
}

table.time__sheet__table td.align-bottom.l-box table {
  border-bottom: 1px solid rgba(186, 176, 176) !important;
  border-top: 0 !important;
}

table.time__sheet__table tbody tr.border-tb td.align-bottom.l-box table tbody.last-box {
  border-right-width: 2px;
  right: 0;
}

// table.time__sheet__table tbody tr.border-tb:first-child td.align-bottom.l-box table tbody {
//   border-bottom: 1px solid rgb(186, 176, 176) !important;
// }

table.time__sheet__table td.align-bottom.l-box {
  border-right: 0;
  border-bottom: 0;
}

//start: fix BORDER RADIUS
.day-header-tr td{
border: solid 3px #707d87;

}

// tr:first-child td:first-child { border-top-left-radius: 10px; }
//  tr:first-child td:last-child { border-top-right-radius: 10px; }

// tr:first-child td { border-top-style: solid; }
// tr td:first-child { border-left-style: solid; }
tr.day-header-tr {
  &:first-child td:first-child {
    border-top-left-radius: 10px;
    padding-left: 3px;
  }

  &:first-child td:last-child {
    border-top-right-radius: 10px;
    padding-right: 3px;
  }

  &:first-child td {
    border-top-style: solid;
  }

  td:first-child {
    border-left-style: solid;
  }
}

//outer border for table-data
tr.outer-border-tr {
  td {
    &:first-child {
      border-left: solid 3px #707d87;
      padding-left: 3px;
    }

    &:last-child {
      border-right: solid 3px #707d87;
      padding-right:3px;
    }
    
  }
}


//end: fix BORDER RADIUS

//start: employee info

.employee-info-left{
 display: flex;
 flex-wrap: wrap;
}

.employee-name{
  margin-right: 12px;
}

.employee-info-right{
  display: flex;
  align-items: center;
}


//end: employee info


.custom-c {
  padding: 5px !important;
}

.custom-c .f-size {
  font-size: 15px;
  width: 100%;
}

.l-box .last-box {
  border-right: 1px solid #000;
  /* bottom: -1px; */
  position: absolute;
  height: 100%;
  width: 100%;
}

.c-size-svg {
  width: 40px !important;
  height: 40px !important;
}

// .time__sheet__table tbody tr th:first-child::before {
//   background: #fff;
//   content: "";
//   position: absolute;
//   left: 0;
//   top: 0;
//   height: 100%;
//   width: 100%;
// }

.w-dropdown-btn .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  max-width: calc(100% - 35px);
}

.service-p-btn .MuiSelect-select.MuiSelect-outlined {
  padding: 10px;
}

.priceUnit-p-btn .MuiSelect-select.MuiSelect-outlined {
  padding: 5px;
  border:none;

}
.discount-field{
  padding: 4px;
  border:1px;
  height:10px;

}
// div#kt_account_profile_details .mfg-dropdown > div input {
//   width: 70px !important;
// }
// div#kt_account_profile_details .mfg-dropdown > div {
//   height: 1.4375em;
//   min-height: 51.69px;
//   margin: 10px;
// }

.card-image img {
  width: 61px;
}

.purchase-order-card-header {
  box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 2%);
  background-color: #fff;
  border-radius: 0.625rem;
  margin-bottom: 15px;
  width: 100%;
  overflow-x: scroll;
}

.card-upperside {
  border-bottom: 1px solid #eff2f5;
}

.card-upperside,
.card-lowerside {
  /* display: inline-flex;
    justify-content: space-evenly; */
  width: 100%;
  padding: 15px 0;
  border-bottom: 0;
}

.purchase-order-card-header table {
  width: 100%;
}

.card-image img {
  width: 61px;
}

.card-content-desc span {
  color: #a6b4c2;
  font-weight: 500;
}

.card-upperside .card-content-desc p {
  font-size: 16px;
}

img.more-icon {
  width: 15px;
  margin-right: 20px;
}

span.delivery-badge {
  background: #a7dbff;
  color: #666;
  padding: 5px 7px 5px 6px;
  border-radius: 6px;
  font-size: 14px;
}

span.delivers-badge {
  background: #ffff00e5;
  color: #666;
  padding: 5px 7px 5px 6px;
  border-radius: 6px;
  font-size: 14px;
}

span.delivery-badge img {
  width: 22px;
  margin-right: 7px;
}

span.delivers-badge img {
  width: 22px;
  margin-right: 7px;
}

span.italic-text {
  font-style: italic;
  font-size: 12px;
  color: #666;
  margin-left: 5px;
}

.dropdown-select p span {
  position: relative;
  font-weight: 400;
  font-size: 12px;
  color: #666;
  margin-left: 5px;
}

.dropdown-select p span:after {
  content: '';
  background-image: url(http://127.0.0.1:3011/media/logos/angle-down.png);
  position: absolute;
  top: 0;
  right: -22px;
  width: 20px;
  height: 20px;
  background-size: 20px;
}

.notes-block,
.upload-block {
  box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 2%);
  background-color: #fff;
  border-radius: 0.625rem;
  margin-bottom: 15px;
  padding: 15px;
}

.notes-block .notes-header,
.upload-block .upload-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.notes-block .notes-header h4,
.upload-block .upload-header h4 {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0;
}

.add-note,
.add-upload {
  background: #e3e3e3;
  padding: 5px 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.add-upload1 {
  background: #f7f7f7;
  padding: 7px;
  border-radius: 30px;
  display: flex;
  align-items: center;
}

.add-note img,
.add-upload img {
  width: 22px;
  margin-right: 5px;
}

.add-note span,
.add-upload span {
  font-size: 20px;
}

.notes-block .notes-header,
.upload-block .upload-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notes-block .notes-header h4,
.upload-block .upload-header h4 {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0;
}

.add-note,
.add-upload {
  background: #e3e3e3;
  padding: 5px 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.add-note img,
.add-upload img {
  width: 22px;
  margin-right: 5px;
}

.add-note span,
.add-upload span {
  font-size: 20px;
}

.chat-box {
  margin-top: 20px;
}

.profile-image img {
  width: 40px;
  object-fit: cover;
  height: 40px;
}

.chat-box-description {
  background: #f7f7f7;
  padding: 15px;
  border-radius: 10px;
}

.chat-box-description-header {
  display: flex;
  gap: 5px;
  margin-bottom: 8px;
  align-items: center;
}

.chat-box-description-header h5 {
  margin-bottom: 0;
  display: block;
  width: 100%;
}

.chat-box-description-header p {
  font-weight: 500;
  color: #abacac;
  margin-bottom: 0;
}

.chat-box-description-body p {
  line-height: 1.2;
  font-weight: 500;
}

.add-note h6 {
  margin-bottom: 0;
  font-size: 12px;
}

.add-upload h6 {
  margin-bottom: 0;
  font-size: 12px;
}

.pdf-list {
  margin-top: 30px;
}

.pdf-list a {
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  color: #3488dd;
  margin-bottom: 10px;
}

.datatable-cont .card .card-header {
  padding-left: 5px;
  padding-right: 5px;
}

.datatable-cont .card .card-header .card-title h3 {
  font-weight: 500;
}

.receive-item-badge img {
  width: 22px;
  margin-right: 7px;
}

.badges-block .delivery-badge {
  margin-right: 20px;
  cursor: pointer;
}

.badges-block .delivers-badge {
  margin-right: 20px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.receive-item-badge {
  background: #a4ffbc;
  color: #666;
  padding: 5px 7px 5px 6px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.datatable-cont .card .card-header .card-title {
  justify-content: space-between;
  width: 100%;
}

.badges-block {
  margin-right: 15px;
  display: flex;
}

.datatable-cont .card .FeitL.rdt_TableCol_Sortable {
  align-items: end;
}

.datatable-cont .card .border-top .iSAVrt {
  min-height: 37px;
}

.datatable-cont .card .border-top .iSAVrt .hbotQl {
  text-transform: uppercase;
  font-weight: 600;
}

.notes-block .notes-header h3,
.upload-block .upload-header h3 {
  margin-bottom: 0;
}

.additional-details-btn {
  background: #f5f5f5;
  border: 0;
  border-radius: 10px;
  color: #95a2af;
  padding: 5px;
  font-size: 11px;
}

.additional-details-btn img {
  filter: invert(75%) sepia(10%) saturate(410%) hue-rotate(169deg) brightness(84%) contrast(90%);
  width: 16px;
}

.footer .container-fluid span {
  color: #d3d5d5 !important;
}

.footer .container-fluid a {
  color: #d3d5d5 !important;
  font-weight: 500;
}

.opacity0 {
  opacity: 0;
}

.purchase-order-card-header table tr td {
  vertical-align: top;
}

.datatable-cont .card .border-top {
  border-top: 0 !important;
}

.active-btn-cls {
  background-color: #ef003c !important;
  color: white !important;
}

.c-uplaod-files a {
  font-size: 14px;
  padding-bottom: 10px;
  color: #3488dd;
}

.c-uplaod-files a:hover {
  color: #a9a9a9;
}

.icon-pack {
  position: absolute;
  color: #aaa;
  right: 32px;
  top: 18px;
}

// .card-upperside .card-content-desc{
//   margin-top:20px;
// }
// .card-upperside .card-content-desc p{
// margin-bottom: 10px;
// }
.notes-card {
  background-color: rgb(247, 247, 247);
  padding: 10px;
  border-radius: 10px;
  width: 100%;
}

.c-w10 {
  width: 10%;
}

.c-w12 {
  width: 12%;
}

.c-w16 {
  width: 16%;
}

.d-border {
  border: 1px solid #cccccc;
}

.t-border-top {
  border-top: 1px solid #000;
}

.t-border-bottom {
  border-bottom: 1px solid #000;
}

.font-size-18 {
  font-size: 18px;
}

.inventory-card-box {
  grid-template-columns: 1fr 1fr;
}

.inventory-card-box .store-inventory {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
  padding: 15px;
}

.inventory-card-box .inventory-item {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
  padding: 15px;
}

.inventory-card-box .transit-po {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
  padding: 15px;
}

.inventory-card-box .transit-rma {
  padding: 15px;
}

.card-svg-icon {
  filter: invert(0.5);
  margin-right: 15px;
}

.information-custom-cards span {
  font-size: 11px;
}

.custom-class_for_po {
  height: 25px;
  width: 100%;
}

.custom-class_for_po input {
  padding: 6px;
  width: 100%;
}

.customer-view-btn {
  gap: 10px;
}

.customer-view-btn button {
  padding: 10px !important;
}

.tabs-titles h5 {
  margin: 0;
}

.activity-content {
  width: 100%;
  margin: auto;
}

.file-tabs-title {
  height: 750px;
  width: 100%;
  margin: auto;
}

.serach-wrapper {
  flex-wrap: wrap;
}

.clk-btn {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.service-action-btn {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.service-action-btn button {
  padding: 12px !important;
}

// add active class  to add this arrow transfomation rotate
// .additional-details-btn img:hover {
//   transform: rotate(180deg);
//   transition: 0.1s;
// }
.roles-cards-wrapper {
  flex-wrap: nowrap !important;
}

.p-lr-16 {
  padding-right: 16px;
  padding-left: 16px;
}

.sticky-wrap:has(> .MuiBox-root) {
  overflow: auto;
}

.items-order-wrapper {
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.item-heading-row {
  display: flex;
}

.mui-icons {
  display: flex;
  align-items: center;
}

.tooltip-icons {
  display: none;
}

.mui-filter-fields {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.mui-filter-wrap {
  gap: 5px;
}

.stepper.stepper-links .stepper-nav {
  flex-wrap: nowrap !important;
}

.quote-activity-wrapper {
  flex-wrap: wrap;
}

.badge-wrap-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.check {
  width: 100% !important;
  align-items: center;
}

.temp {
  width: 25% !important;
}

.god {
  width: 60%;
}

.sub-god {
  width: 33%;
}

.sub-god_1 {
  width: 33%;
}

.sub-god_2 {
  width: 50%;
}

.sub-god_v2 {
  width: 100%;
}

.god-child {
  width: 70%;
  display: flex;
  gap: 10px;
  align-items: center;
}

.god-father {
  display: flex;
  align-items: center;
  gap: 10px;
}

.god-father_1 {
  width: 70%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.god-father_2 {
  width: 50%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.god-father_v2 {
  width: 50%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.customer-filter {
  width: 200px;
}

@media (max-width: 1400px) {
  .god-child {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .sub-god {
    width: 100%;
  }

  .god {
    width: 40%;
  }

  .temp {
    width: 100% !important;
  }

  // .notes-card .file-data-info {
  //   flex-direction: column;
  // }
  .chat-box-description-header {
    flex-direction: column;
    align-items: stretch !important;
    width: 100%;
  }

  .task-wrap-tables {
    grid-template-columns: 1fr !important;
  }
  .mui-filter-fields {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

@media (max-width: 1200px) {
  .god-father {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .customer-filter {
    width: 100%;
  }

  .customer-view-btn {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .notes-card .file-data-info {
    flex-direction: column;
  }

  .purchase-order-card-header table {
    width: 1000px;
  }

  .notes-block-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .notes-block,
  .upload-block {
    width: 100%;
  }

  .view-emp-wrapper {
    grid-template-columns: 1fr !important;
  }

  .info-badge {
    font-size: 10px !important;
  }

  .mui-icons {
    justify-content: flex-start;
  }
}

@media (min-width: 992px) {
  .menu-lg-row > .menu-item {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .tab-grid-content {
    grid-template-columns: auto auto !important;
  }

  .file-tabs-title {
    height: auto;
  }

  .file-tabs-title .control-section {
    flex-direction: row !important;
  }

  .file-tabs-title .control-section-title {
    overflow: scroll;
    justify-content: space-between;
  }

  .view-emp-img {
    width: 100%;
    justify-content: center;
  }

  .shipping-cost-field {
    padding-bottom: 15px;
  }

  .m-top-10 {
    margin-top: 15px;
  }

  .c-width100 {
    width: 100% !important;
  }

  .mui-filter-fields {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

@media (max-width: 767px) {
  .sub-god_1 {
    width: 100%;
  }

  .god-father_1 {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .header-fixed.toolbar-fixed .wrapper.main-content-block {
    padding-top: 56px;
  }

  .tabs-titles {
    min-width: 550px;
    padding: 15px !important;
  }

  .tabs-titles h5 {
    font-size: 11px;
  }

  .tab-grid-content {
    grid-template-columns: auto !important;
  }

  .custom-row {
    gap: 10px;
  }

  // .serach-wrapper {
  //   flex-direction: column;
  // }

  .notes-card .file-data-info {
    flex-direction: column;
  }

  .notes-card .file-data-info span {
    font-size: 10px;
  }

  .quote-activity-wrapper {
    flex-direction: column;
    align-items: flex-start !important;
  }

  // .quote-activity-wrapper span.svg-icon {
  //   width: 100%;
  //   text-align: center;
  // }
  .file-tabs-title {
    height: auto;
  }

  .file-tabs-title .control-section {
    flex-direction: row !important;
  }

  .file-tabs-title .control-section-title {
    overflow: scroll;
  }

  .file-tabs-title .control-section-title button {
    font-size: 14px !important;
    padding: 15px !important;
  }

  .clk-btn {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .timesheet-wrap {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .inventory-view-tabs h5 {
    font-size: 14px !important;
  }

  .items-order-wrapper {
    justify-content: space-around;
    width: 100%;
  }

  .items-order-wrapper .search-wrap {
    width: 100% !important;
  }

  .notes-block-wrapper {
    flex-direction: column;
  }

  .total-order-fileds {
    gap: 10px;
  }

  .purchase-order-total .total-order-fileds > div {
    justify-content: flex-start !important;
  }

  .add-items-btn button {
    width: 100% !important;
  }

  .return-card-wrapper {
    flex-direction: column;
  }

  .return-card-wrapper button {
    width: 100% !important;
  }

  .company-timesheet-wrapper .wrap-fileds {
    float: none !important;
  }

  .time__sheet__table thead.border-tb .head-row > th:last-child .full-height {
    width: 75px !important;
  }

  .time__sheet__table .border-tb .full-height {
    padding-left: 10px;
    padding-right: 10px;
  }

  .p-top-10 {
    padding-top: 10px;
  }

  .m-top-10 {
    margin-top: 0px;
  }

  .tooltip-icons {
    display: block;
  }
}

@media (max-width: 567px) {
  .god-father_1,
  .god-father_2,
  .god-father_v2 {
    width: 100%;
  }

  .god-child {
    width: 100%;
    grid-template-columns: 1fr;
  }

  .god {
    width: 100%;
  }

  .company-logo-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .mui-table-header-custom {
    flex-direction: column;
  }

  .mui-icons {
    display: flex;
    align-items: center;
  }

  // .mui-sticky-header {
  //   padding-left: 34px;
  // }

  .mui-filter-fields {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .mui-filter-wrap {
    flex-direction: column;
    align-items: center;
  }

  .badges-block {
    display: flex;
    gap: 5px;
    margin-right: 0;
  }

  .badges-block .delivers-badge {
    margin-right: 0px;
  }

  .badges-block .receive-item-badge {
    margin-right: 0 !important;
  }
}

.projects-dec p {
  margin: 0;
}

.notes-desc p {
  margin: 0 !important;
}

.project-desc-head {
  min-height: 25px !important;
  margin-bottom: 10px !important;
}

.task-wrap-tables .card-body {
  padding: 8px 5px;
}

@media (max-width: 991px) {
  .nav-mobile-icons {
    display: none !important;
  }

  .items-order-wrapper {
    flex-direction: column;
  }

  .item-heading-row {
    flex-direction: column;
  }
}

.table-grid-inventory {
  display: grid;
}

.table-row-inventory {
  display: flex;
  align-items: center;
}

.table-col-inventory {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.col-gap {
  gap: 10px;
}

@media (max-width: 375px) {
  .block-display {
    display: block;
  }
}
#kt_body{
  overflow:inherit;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.dropbtn {
  background-color: #3498DB;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover, .dropbtn:focus {
  background-color: #2980B9;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {background-color: #ddd;}

.dropdown_show {display: block;}

select#demo-simple-select:focus-visible {
  outline: 0;
}

input#demo-simple-select:focus-visible {
  outline: 0;
}

.custom-file-container:hover{
  border-color: black !important;
  color: black !important;
  background-color: white !important;
}