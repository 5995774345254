
.mainFormOuter {
    display: inline-block;
    max-width: 450px;
    width: 100%;
    padding: 0 20px;
}

.brandImg{
    display: inline-block;
    max-width: 450px;
    width: 100%;
}
.inline-menu {
    li {
        display: inline-block;
        margin: 0 10px;
        position: relative;
        &:before {
            content: "";
            height: 3px;
            width: 3px;
            border-radius: 50%;
            background: #fff;
            display: block;
            position: absolute;
            left: -11px;
            top: 9px;
        }
        &:first-child {
            &:before {
                display: none;
            }
        }
        a {
            color: #fff;
        }
    }
}

@media(min-width: 768px){
    .desktopHalfRedBg {
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(239,0,60,1) 50%);
    }
}

@media(max-width: 767px) and (orientation: portrait){
    .mobileHalfRedBg {
        background: $primary;
    }
}